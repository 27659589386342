<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>常用工具入口</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 顶部区域 -->
    <el-card class="box-card box-card-top">
      <div class="box-card-top-body">
        <span style="color: darkgreen;font-size: 17px;font-weight: 400; text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* 添加阴影 */">多种好用的小工具入口，都在这里了 ~</span>
        <!-- <el-input
          placeholder="请输入工具名称"
          prefix-icon="el-icon-search"
          clearable
          style="width: 15%;"
          v-model="helpToolsInput">
        </el-input> -->
        <div style="display: flex">
          <el-tooltip class="item" effect="dark" content="添加新工具" placement="top">
            <el-button type="primary" circle plain style="padding: 5px;margin-right: 20px;" @click="addDialogVisibleClick">
              <i class="el-icon-circle-plus" style="font-size: 20px;"></i>
            </el-button>
          </el-tooltip>
          <el-autocomplete
            class="inline-input"
            placeholder="请输入工具名称"
            clearable
            v-model="state1"
            :fetch-suggestions="querySearch"
            value-key="tools_name"
            @select="handleSelect"
            @clear="handleClear"
          ></el-autocomplete>
        </div>
      </div>
    </el-card>

    <!-- 测试工具卡片 -->
    <el-row :gutter="10">
      <el-col :span="6" v-for="helpToolsInfo in helpToolsList" :key="helpToolsInfo.tools_id">
        <el-card class="box-card-tools" shadow="hover">
          <div slot="header" class="box-card-title">
            <!-- <span>卡片名称</span> -->
            <el-avatar size="small" :src="helpToolsInfo.icon_url"></el-avatar>
            <el-link class="title-text" :href="helpToolsInfo.web_url" target="_blank">{{ helpToolsInfo.tools_name }}</el-link>
            <!-- <h3 style="float: left;margin: 0 0;"></h3> -->
          </div>
          <span class="box-card-content">{{ helpToolsInfo.description }}</span>
        </el-card>
      </el-col>
    </el-row>

    <!-- 分页处理 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[4, 16, 40, 160]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageInfo.total_count">
    </el-pagination>

    <!-- 添加弹窗 -->
    <el-dialog
      title="添加工具"
      :visible.sync="addDialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form :model="AddToolsRuleForm" :rules="addToolsRules" ref="addToolsRuleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="工具名称" prop="tools_name">
          <el-input v-model="AddToolsRuleForm.tools_name"></el-input>
        </el-form-item>
        <el-form-item label="工具网址" prop="web_url">
          <el-input v-model="AddToolsRuleForm.web_url"></el-input>
        </el-form-item>
        <el-form-item label="工具图标" prop="icon_url">
          <el-input v-model="AddToolsRuleForm.icon_url"></el-input>
        </el-form-item>
        <el-form-item label="工具描述" prop="description">
          <el-input type="textarea" v-model="AddToolsRuleForm.description"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addHandleTools">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import { getHelpToolslist, addHelpTools } from '@/services/helpTools.js'

export default {
  name: 'HelpTools',
  data () {
    return {
      helpToolsInput: '压缩',
      state1: '',
      helpToolsList: [],
      pageInfo: {
        currentPage: 1,
        size: 16,
        total_count: null
      },
      addDialogVisible: false,
      AddToolsRuleForm: {
        tools_name: '',
        web_url: '',
        icon_url: '',
        description: ''
      },
      addToolsRules: {
        tools_name: [
          { required: true, message: '请输入工具名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ],
        web_url: [
          { required: true, message: '请输入链接地址', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
        ],
        icon_url: [
          { required: true, message: '请输入链接地址', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
        ],
        description: [
          { required: false, message: '请输入工具描述', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 500 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadHelpToolsList()
  },
  mounted () {
    // this.helpToolsList =
  },
  methods: {
    async loadHelpToolsList () {
      const queryObj = {
        query_type: 'query_list',
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size
      }
      const { data: res } = await getHelpToolslist(queryObj)
      if (res.status === 200) {
        this.helpToolsList = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    querySearch (queryString, cb) {
      var restaurants = this.helpToolsList;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter (queryString) {
      return (restaurant) => {
        return (restaurant.tools_name.toLowerCase().includes(queryString.toLowerCase()));
      };
    },
    handleSelect (item) {
      this.helpToolsList = []
      this.helpToolsList.push(item)
      console.log(item)
    },
    handleClear () {
      this.loadHelpToolsList()
    },
    addDialogVisibleClick () {
      this.addDialogVisible = true
    },
    addHandleTools () {
      this.$refs.addToolsRuleForm.validate(async (valid) => {
        if (valid) {
          console.log('点击添加')
          const addObj = this.AddToolsRuleForm
          const { data: res } = await addHelpTools(addObj)
          if (res.status === 200) {
            this.$message.success('添加成功')
            this.loadHelpToolsList()
            this.addDialogVisible = false
            this.$refs.addToolsRuleForm.resetFields()
          } else this.$message.warning(res.message)
        } else {
          this.$message.info('请检查填写信息')
          return false;
        }
      })
    },
    handleSizeChange (val) {
      // 用例信息 分页处理(每页)
      this.pageInfo.size = val
      this.loadHelpToolsList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // 用例信息 分页处理(当前页)
      this.pageInfo.currentPage = val
      this.loadHelpToolsList()
      console.log(`当前页: ${val}`)
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
}

</script>

<style lang="scss" scoped>

  .box-card-top {
    padding: 0px;
    margin-bottom: 10px;
  }

  .box-card-top-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box-card-tools {
    margin-top: 15px;
    border-radius: 12px; /* 设置圆角半径 */
    overflow: hidden; /* 防止内容溢出 */
    /* box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1); /* 添加阴影以增强视觉效果 */
  }

  .box-card-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .box-card-title .title-text {
    margin-left: 10px;
  }

  .box-card-content {
    display: inline-block;
    font-size: 12px;
    width: 95%;
    overflow: hidden;
    white-space: nowrap; /* 禁止换行 */
    text-overflow: ellipsis;   /* 显示省略号 */
  }

</style>
