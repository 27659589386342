import request from '@/utils/request.js'

// 查询常用工具列表
export const getHelpToolslist = (data) => {
  return request({
    method: 'GET',
    url: 'helpTools',
    params: { ...data }
  })
}

// 添加常用工具
export const addHelpTools = (data) => {
  return request({
    method: 'POST',
    url: 'helpTools',
    data
  })
}
